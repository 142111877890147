

//LOGOS
import logoImg from 'images/logo.png';
var logo = document.getElementById('logoImg');
logo.src = logoImg;

import logoNavbarImg from 'images/logo-bg-green.png';
var logoNavbar = document.getElementById('logoNavbarImg');
logoNavbar.src = logoNavbarImg;

// import logoBgBlackImg from 'images/logo-bg-black.png';
// var logoBgBlack = document.getElementById('logoBgBlackImg');
// logoBgBlack.src = logoBgBlackImg;

// import logoBgGreenImg from 'images/logo-bg-green.png';
// var logoBgGreen = document.getElementById('logoBgGreenImg');
// logoBgGreen.src = logoBgGreenImg;


//BTN APPSTORE PLAYSTORE
import appstoreImg from 'images/appstore.png';
var appstore = document.getElementById('appstoreImg');
appstore.src = appstoreImg;

import playstoreImg from 'images/playstore.png';
var playstore = document.getElementById('playstoreImg');
playstore.src = playstoreImg;

var appstore2 = document.getElementById('appstoreImg2');
appstore2.src = appstoreImg;

var playstore2 = document.getElementById('playstoreImg2');
playstore2.src = playstoreImg;

var appstore3 = document.getElementById('appstoreImg3');
appstore3.src = appstoreImg;

var playstore3 = document.getElementById('playstoreImg3');
playstore3.src = playstoreImg;



//MOCKUPS
import mockupLoginImg from 'images/mockup-login.png';
var mockupLogin = document.getElementById('mockupLoginImg');
mockupLogin.src = mockupLoginImg;

import mockupLoginSmallScreenImg from 'images/mockup-login-small-screen.png';
var mockupLoginSmallScreen = document.getElementById('mockupLoginSmallScreenImg');
mockupLoginSmallScreen.src = mockupLoginSmallScreenImg;



// import mockupLoginSmartScreenImg from 'images/mockup-login-smart-screen.png';
// var mockupLoginSmartScreen = document.getElementById('mockupLoginSmartScreenImg');
// mockupLoginSmartScreen.src = mockupLoginSmartScreenImg;

import mockupLoginSmartScreenImg from 'images/mockup-login-smart-screen-portrait.png';
var mockupLoginSmartScreen = document.getElementById('mockupLoginSmartScreenImg');
mockupLoginSmartScreen.src = mockupLoginSmartScreenImg;



import mockupAumenteLeftImg from 'images/mockup-aumente-left.png';
var mockupAumenteLeft = document.getElementById('mockupAumenteLeftImg');
mockupAumenteLeft.src = mockupAumenteLeftImg;

import mockupAumenteRightImg from 'images/mockup-aumente-right.png';
var mockupAumenteRight = document.getElementById('mockupAumenteRightImg');
mockupAumenteRight.src = mockupAumenteRightImg;

import mockupSeuJeitoImg from 'images/mockup-seu-jeito-full.png';
var mockupSeuJeito = document.getElementById('mockupSeuJeitoImg');
mockupSeuJeito.src = mockupSeuJeitoImg;

import mockupSeuJeitoScreen01Img from 'images/mockup-seu-jeito-screen-01.png';
var mockupSeuJeitoScreen01 = document.getElementById('mockupSeuJeitoScreen01Img');
mockupSeuJeitoScreen01.src = mockupSeuJeitoScreen01Img;

import mockupSeuJeitoScreen02Img from 'images/mockup-seu-jeito-screen-02.png';
var mockupSeuJeitoScreen02 = document.getElementById('mockupSeuJeitoScreen02Img');
mockupSeuJeitoScreen02.src = mockupSeuJeitoScreen02Img;

import mockupEconomiaLeftImg from 'images/mockup-economia-left.png';
var mockupEconomiaLeft = document.getElementById('mockupEconomiaLeftImg');
mockupEconomiaLeft.src = mockupEconomiaLeftImg;

import mockupEconomiaRightImg from 'images/mockup-economia-right.png';
var mockupEconomiaRight = document.getElementById('mockupEconomiaRightImg');
mockupEconomiaRight.src = mockupEconomiaRightImg;

import mockupTesteLeftImg from 'images/mockup-playStore.png';
var mockupTesteLeft = document.getElementById('mockupTesteLeftImg');
mockupTesteLeft.src = mockupTesteLeftImg;

import mockupTesteRightImg from'images/mockup-economia-right.png';
var mockupTesteRight = document.getElementById('mockupTesteRightImg');
mockupTesteRight.src = mockupTesteRightImg;




//IMAGES
import visibilidadeImg from 'images/visibilidade.png';
var visibilidade = document.getElementById('visibilidadeImg');
visibilidade.src = visibilidadeImg;

import poweredby from 'images/poweredby.png';
var powered = document.getElementById('poweredby');
powered.src = poweredby;



// FAVICONS
import faviconImgAppleTouch from '../apple-touch-icon.png';
var faviconAppleTouch = document.getElementById('faviconImgAppleTouch');
faviconAppleTouch.href = faviconImgAppleTouch;

import faviconImg32 from '../favicon-32x32.png';
var favicon32 = document.getElementById('faviconImg32');
favicon32.href = faviconImg32;

import faviconImg16 from '../favicon-16x16.png';
var favicon16 = document.getElementById('faviconImg16');
favicon16.href = faviconImg16;

import faviconImgSVG from '../safari-pinned-tab.svg';
var faviconSVG = document.getElementById('faviconImgSVG');
faviconSVG.href = faviconImgSVG;

import '../android-chrome-192x192.png';

import '../android-chrome-512x512.png';

import  '../mstile-150x150.png';

